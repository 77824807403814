import 'intersection-observer';
import React, { Suspense, lazy } from 'react';

import * as ReactDOM from 'react-dom/client';
import { defaults } from '@developers/queries/defaults';

import helpers from '@helpers/helpers';

import reportWebVitals from './reportWebVitals';
import useGlobalState, { TSConfig } from './useGlobalStore';
import { sanitizeConfig } from './helpers/widget';

const B2C_BROKER_ID = '1212121212121212';

declare global {
  interface Document {
    documentMode?: number;
  }

  interface File {
    invalidFileType?: boolean;
  }

  interface Window {
    ts: TSPortal;
  }
}

interface TSPortal {
  load: (config: TSConfig) => void;
}

// lazy loading App component because we need to set queries default baseUrl before the App component code is loaded
const App = lazy(() => import('./App'));

const load = (config?: TSConfig) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.get('externalCustomerId') && config) {
    // eslint-disable-next-line no-param-reassign
    config.externalCustomerId = urlParams.get('externalCustomerId') as string;
  }
  useGlobalState.setState({
    portalConfig: {
      ...useGlobalState.getState().portalConfig,
      ...sanitizeConfig(config),
    } as TSConfig,
  });

  defaults.baseUrl = `${helpers.getDefaultGateway()}`;

  if (helpers.envVariables.NX_PUBLIC_IS_WIDGET_DEV_MODE) {
    // eslint-disable-next-line no-console
    console.log('widget configuration:', { config, env: helpers.envVariables });
  }

  document.body.classList.add('ts-app');

  const root = ReactDOM.createRoot(
    document.getElementById(config?.renderId || 'root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </React.StrictMode>
  );
};

window.ts = {
  load,
};

if (helpers.envVariables.NX_PUBLIC_IS_WIDGET_DEV_MODE) {
  load({
    brokerCode: B2C_BROKER_ID,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
