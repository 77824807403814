import { createWithEqualityFn } from 'zustand/traditional';

export enum EventType {
  RouteChange = 'route_change',
  SignAContract = 'sign_a_contract',
}

export interface TSEvent {
  name: EventType;
  payload: unknown;
}
export interface TSConfig {
  renderId?: string;
  brokerCode?: string;
  subDomain?: boolean;
  professionAliasIds?: number[];
  productId?: number;
  header?: boolean;
  footer?: boolean;
  fallbackUrl?: string;
  minimalLayout?: boolean;
  eventsCallback?: (event: TSEvent) => void;
  externalCustomerId?: string;
  hideNavigationBar?: boolean;
  callbackUrlOnQaSubmit?: string;
}

interface GlobalState {
  portalConfig?: TSConfig;
}

const useGlobalStore = createWithEqualityFn<GlobalState>()(
  (set, get) => ({
    portalConfig: undefined,
  }),
  Object.is
);

export default useGlobalStore;
