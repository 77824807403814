export const defaults = (() => {
  const values = {
    baseUrl: '',
    serverBaseUrl: '',
    internalBaseUrl: '',
  };

  return {
    get baseUrl() {
      return values.baseUrl;
    },
    set baseUrl(url: string) {
      values.baseUrl = url;
    },
    get serverBaseUrl() {
      return values.serverBaseUrl;
    },
    set serverBaseUrl(url: string) {
      values.serverBaseUrl = url;
    },
    get internalBaseUrl() {
      return values.internalBaseUrl;
    },
    set internalBaseUrl(url: string) {
      values.internalBaseUrl = url;
    },
  };
})();
