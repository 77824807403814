import { TSConfig } from '../useGlobalStore';

export const sanitizeConfig = (config?: TSConfig): TSConfig | undefined => {
  if (config?.professionAliasIds && config.professionAliasIds.length === 0) {
    // eslint-disable-next-line no-param-reassign
    config.professionAliasIds = undefined;
  }

  return config;
};
